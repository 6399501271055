@font-face {
  font-family: 'Chicago Kare';
  src: url('fonts/ChicagoKare-Regular.woff2') format('woff2'),
  url('fonts/ChicagoKare-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geneva 9';
  src: url('fonts/Geneva9.woff2') format('woff2'),
  url('fonts/Geneva9.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


/**
*:hover, * {
  cursor: url("/cursor.svg"), auto !important;
}
*/
html, body, #root{
  font-family: 'Geneva 9';
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;
}
.dialog .title h1{
  font-family: "Chicago Kare";
  font-size:1.7em;
  font-weight: normal;
}
.dialog .title h2 {
  font-family: "Chicago Kare";
  font-size:1.1em;
  font-weight: lighter;
}

.App {
  height: 100%;
  width: 100%;
  position: relative;
}
.intro {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  .introImage {
    animation-name: hide;
    opacity: 1;
    animation-duration: 0.2s;
    animation-delay: 2s;
    animation-fill-mode: both;

  }
}

.page {
  position: absolute;
  inset: 0;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-duration: .3s;
  animation-delay: 2s;
  animation-name: show-page;
  opacity: 0;
}

@keyframes show-page {
  0% {
    opacity: 0;
  }
  2%{
    opacity: 1;
  }
  100%{
    opacity: 1;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes hello-loop {
  0% {

  }
}

@keyframes turn-on-v {
  0% {
    transform: scaleY(0%) scaleX(2%);
    background: white;
    opacity: 0;
  }
  5% {
    transform: scaleY(.15%) scaleX(2%);
    opacity: 1;
  }
  20% {
    transform: scaleY(.15%) scaleX(50%);
    opacity: 1;
    background: white;
  }
  30% {
    transform: scaleY(30%)  scalex(100%);
    background: #888888;
  }
  50% {
    transform: scaleY(100%) scalex(100%);
  }
  55%{
    transform: scaleY(100%) scalex(100%);
  }
  100% {
    background: #888888;
    transform: scaleY(100%) scalex(100%);
    opacity: 1;
  }
}


.intro {
  animation-delay: .5s !important;
  background: white;
  animation: turn-on-v;
  animation-fill-mode: both;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}

.scanlines {
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  inset: 0;
  opacity: 3%;
  background: linear-gradient(
  to bottom,
  transparent 50%,
  black 51%);
  background-size: 100% 3px;
  box-shadow: inset 0 0 13px 2px #00000075;
}

h1, h2 {
  margin: 0;
  padding: 0;
  text-align: center;
}
h1 {
  font-size: 18px;
}
h2 {
  font-size: 12px;
  font-weight: normal;
}
.dialog_box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .dialog {
    position: relative;
    height: 160px;
    background: white;
    box-shadow: 2px 2px 0 black;
    border: 2px solid black;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    transform-origin: center;
    gap: 30px;
    width: 300px;
    /*padding: 15px 33px 15px 25px;*/
    /*padding: 21px 39px 21px 31px;*/
    padding: 24px 42px 24px 34px;

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .bottom {
      position: absolute;
      bottom: 4px;
      left: 0;
      right: 0;
      display: flex;
      padding: 0 10px;
      font-size: 14px;
      justify-content: space-between;
      flex-direction: row;
    }
  }
}
.icons {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
  &.flipped {
    fill: black !important;
  }

  .chainIcon {
    animation-name: show;
    animation-fill-mode: both;
    animation-delay: 0s;
  }
  &.main_page {
    .chainIcon:nth-of-type(1) {
      animation-delay: 3s !important;
    }
   .chainIcon:nth-of-type(2) {
      animation-delay: 3.5s !important;
    }
    .chainIcon:nth-of-type(3) {
      animation-delay: 4s !important;
    }
    &.noanim .chainIcon {
        animation-delay: 0s !important;
    }

  }
  .chainImage {
    width: 55px;
  }
  .chainIcon {
    display: flex;
    text-decoration: none;
    flex-direction: column;
    align-items: center;
    .label {
      padding: 1px 6px;
      text-align: center;
      width: max-content;
      color: black;
      background: white;
      margin-top: 2px;
      font-weight: 300;
      font-size: 0.75em;
    }

  }
}