.chain_page {
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background .3s ease;
  &.optimism {
    background: black;
  }
  &.tezos {
    background: white;
  }
  .info {
    position: absolute;
    display: flex;
    left: 0;
    right: 0;
    bottom: 160px;
    flex-direction: column;
    align-items: center;
    font-size: 0.9em;
    a, div {
      color: black;
      text-decoration: none;
      background: white;
      padding: 0 4px;
    }
    a:hover {
      color: white;
      background: black;
    }
  }
}

.help {
  position: absolute;
  top: 5px;
  right: 30px;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}
.home {
  position: absolute;
  top: 5px;
  right: 90px;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

.dialog {
  position: relative;
  height: 70px;
  background: white;
  box-shadow: 0 0 0 4px white, 0 0 0 6px black, 4px 4px 0 6px black;
  border: 4px solid black;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: max-content;
  align-items: center;
  transform-origin: center;
  gap: 30px;
  padding: 36px 42px 24px 34px;
}

$cols: ("normal": #000, "optimism": #FF0420);
$textcols: ("normal": "white", "optimism": "white");
$opts: ["normal", "optimism"];

@each $name in $opts {
  .dialog.#{$name} a{
    margin-top: -10px;
    padding-top: 10px;
    button{
      font-family: "Chicago Kare" !important;
      background: transparent;
      border: 2px solid black;
      height: 26px;
      cursor: pointer;
      padding-left: 30px;
      padding-right: 30px;}

    &:nth-of-type(1) button {
      background: white;
      border: none;
      box-shadow: 0   -8px 0 -4px white, 0 8px 0 -4px white,
      0   -4px 0 -2px white, 0 4px 0 -2px white,
      -4px -8px 0 -4px map-get($cols, $name), -4px 8px 0 -4px map-get($cols, $name), 4px -8px 0 -4px map-get($cols, $name), 4px 8px 0 -4px map-get($cols, $name),
      -6px -4px 0 -4px map-get($cols, $name), -6px 4px 0 -4px map-get($cols, $name), 6px -4px 0 -4px map-get($cols, $name), 6px 4px 0 -4px map-get($cols, $name),
      0   -10px 0 -4px map-get($cols, $name), 0 10px 0 -4px map-get($cols, $name);
    }
    &:nth-of-type(1):hover button {
      background: map-get($cols, $name);
      border: none;
      box-shadow: 0   -8px 0 -4px map-get($cols, $name), 0 8px 0 -4px map-get($cols, $name),
      0   -4px 0 -2px map-get($cols, $name), 0 4px 0 -2px map-get($cols, $name),
      -4px -8px 0 -4px map-get($cols, $name), -4px 8px 0 -4px map-get($cols, $name), 4px -8px 0 -4px map-get($cols, $name), 4px 8px 0 -4px map-get($cols, $name),
      -6px -4px 0 -4px map-get($cols, $name), -6px 4px 0 -4px map-get($cols, $name), 6px -4px 0 -4px map-get($cols, $name), 6px 4px 0 -4px map-get($cols, $name),
      0   -10px 0 -4px map-get($cols, $name), 0 10px 0 -4px map-get($cols, $name);
      color: white;
      svg {
        fill: white !important;
      }

    }

    &:nth-of-type(2){
      background: pink;
      button {
        background: white;
        border: none;
        box-shadow: 0   -8px 0 -4px white, 0 8px 0 -4px white,
        0   -4px 0 -2px white, 0 4px 0 -2px white,
        -4px -8px 0 -4px  map-get($cols, $name), -4px 8px 0 -4px  map-get($cols, $name), 4px -8px 0 -4px  map-get($cols, $name), 4px 8px 0 -4px  map-get($cols, $name),
        -6px -4px 0 -4px  map-get($cols, $name), -6px 4px 0 -4px  map-get($cols, $name), 6px -4px 0 -4px  map-get($cols, $name), 6px 4px 0 -4px  map-get($cols, $name),
        0   -10px 0 -4px  map-get($cols, $name), 0 10px 0 -4px  map-get($cols, $name),
        -2px -12px 0 -4px white, -2px 12px 0 -4px white, 2px -12px 0 -4px white, 2px 12px 0 -4px white,
        -6px -10px 0 -4px white, -6px 10px 0 -4px white, 6px -10px 0 -4px white, 6px 10px 0 -4px white,
        -8px -6px 0 -4px white, -8px 6px 0 -4px white, 8px -6px 0 -4px white, 8px 6px 0 -4px white,
        -10px -4px 0 0  map-get($cols, $name), -10px 4px 0 0  map-get($cols, $name), 10px -4px 0 0  map-get($cols, $name), 10px 4px 0 0  map-get($cols, $name),
        -8px -8px 0 0  map-get($cols, $name), -8px 8px 0 0  map-get($cols, $name), 8px -8px 0 0  map-get($cols, $name), 8px 8px 0 0  map-get($cols, $name),
        -6px -10px 0 0  map-get($cols, $name), -6px 10px 0 0  map-get($cols, $name), 6px -10px 0 0  map-get($cols, $name), 6px 10px 0 0  map-get($cols, $name),
        -4px -12px 0 0  map-get($cols, $name), -4px 12px 0 0  map-get($cols, $name), 4px -12px 0 0  map-get($cols, $name), 4px 12px 0 0  map-get($cols, $name),
        0 -14px 0 0  map-get($cols, $name), 0 14px 0 0  map-get($cols, $name);
      }
    }
    &:nth-of-type(2):hover button {
      background:  map-get($cols, $name);
      border: none;
      box-shadow: 0   -8px 0 -4px  map-get($cols, $name), 0 8px 0 -4px  map-get($cols, $name),
      0   -4px 0 -2px  map-get($cols, $name), 0 4px 0 -2px  map-get($cols, $name),
      -4px -8px 0 -4px  map-get($cols, $name), -4px 8px 0 -4px  map-get($cols, $name), 4px -8px 0 -4px  map-get($cols, $name), 4px 8px 0 -4px  map-get($cols, $name),
      -6px -4px 0 -4px  map-get($cols, $name), -6px 4px 0 -4px  map-get($cols, $name), 6px -4px 0 -4px  map-get($cols, $name), 6px 4px 0 -4px  map-get($cols, $name),
      0   -10px 0 -4px  map-get($cols, $name), 0 10px 0 -4px  map-get($cols, $name),
      -2px -12px 0 -4px white, -2px 12px 0 -4px white, 2px -12px 0 -4px white, 2px 12px 0 -4px white,
      -6px -10px 0 -4px white, -6px 10px 0 -4px white, 6px -10px 0 -4px white, 6px 10px 0 -4px white,
      -8px -6px 0 -4px white, -8px 6px 0 -4px white, 8px -6px 0 -4px white, 8px 6px 0 -4px white,
      -10px -4px 0 0  map-get($cols, $name), -10px 4px 0 0  map-get($cols, $name), 10px -4px 0 0  map-get($cols, $name), 10px 4px 0 0  map-get($cols, $name),
      -8px -8px 0 0  map-get($cols, $name), -8px 8px 0 0  map-get($cols, $name), 8px -8px 0 0  map-get($cols, $name), 8px 8px 0 0  map-get($cols, $name),
      -6px -10px 0 0  map-get($cols, $name), -6px 10px 0 0  map-get($cols, $name), 6px -10px 0 0  map-get($cols, $name), 6px 10px 0 0  map-get($cols, $name),
      -4px -12px 0 0  map-get($cols, $name), -4px 12px 0 0  map-get($cols, $name), 4px -12px 0 0  map-get($cols, $name), 4px 12px 0 0  map-get($cols, $name),
      0 -14px 0 0  map-get($cols, $name), 0 14px 0 0  map-get($cols, $name);
      color: white;
      svg {
        fill: white !important;
      }

    }
  }

}

.optimism.dialog {
  box-shadow: 0 0 0 4px white, 0 0 0 6px #FF0420, 4px 4px 0 6px #FF0420;
  border: 4px solid #FF0420;
}

.optimism.dialog a:nth-of-type(1) button {
  background: white;
  border: none;
  box-shadow:
          0   -8px  0 -4px white,    0   8px  0 -4px white,
          0   -4px  0 -2px white,    0   4px  0 -2px white,

          -4px -8px  0 -4px #FF0420, -4px 8px  0 -4px #FF0420, 4px -8px 0 -4px #FF0420, 4px 8px 0 -4px #FF0420,
          -6px -4px  0 -4px #FF0420, -6px 4px  0 -4px #FF0420, 6px -4px 0 -4px #FF0420, 6px 4px 0 -4px #FF0420,
          0   -10px 0 -4px #FF0420,  0   10px 0 -4px #FF0420;
}


button:nth-of-type(2):hover svg{
  fill: white
}
.optimism.dialog button:nth-of-type(1) svg{
  fill: #FF0420;
}
svg {
  image-rendering: pixelated;
  margin-top: 3px;
  pointer-events: none;
}

.loading_bar {
  position: absolute;
  inset: 0;
  background: white;
  display: flex;
  justify-content: stretch;
  align-content: center;
  align-items: center;
  padding: 45px;
  animation-duration: 0.6s;
  animation-fill-mode: both;

  .bar {
    border: 3px solid black;
    height: 25px;
    width: 100%;
    position: relative;

    .progress {
      position: absolute;
      inset: 0;
      margin-left: -2px;
      background: black;
      transform-origin: left;
      transform: scaleX(0);
      animation-duration: 0.5s;
      animation-fill-mode: both;
    }
  }

  .run_animation &{
    animation-name: loading;
    animation-duration: 0.7s;
    animation-fill-mode: both;
    .bar .progress {
      animation-name: progress_bar;
      animation-fill-mode: both;
      animation-timing-function: steps(6);
    }
    .etherium & {
      animation-duration: 0.5s;
      .bar.progress {
        animation-duration: 0.4s;
        animation-timing-function: steps(4);
      }
    }
    .tezos & {
      animation-duration: 0.7s;
      .bar .progress {
        animation-duration: 0.5s;
        animation-timing-function: steps(6);
      }
    }
    .optimism & {
      animation-duration: 0.2s;
      .bar .progress {
        animation-duration: 0.1s;
        animation-timing-function: steps(6);
      }
    }
  }

}


@keyframes progress_bar {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}
@keyframes loading {
  0% {
    opacity: 1;
    pointer-events: all;
  }
  99% {
    opacity: 1;
    pointer-events: all;
  }
  100% {
    opacity: 0;
    pointer-events: none;
  }
}